import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import App from './App'

const mapStateToProps = () => {
  const load = true

  return {
    loaded: load,
  }
}

// const matchDispatchToProps = {
//   setUser,
//   removeUser,
// }

export default withRouter(connect(mapStateToProps, null)(App))
