import styled, { createGlobalStyle } from 'styled-components'
import Variable from 'Constant/variable.styled'

// eslint-disable-next-line import/prefer-default-export
export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    font-size: 17px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  #app {
    width: 100%;
    height: 100%;
  }

  .page-holder {
    margin: 0 auto;
    width: 100%;
    max-width: 1240px;
    padding: 0 16px;
  }

  main img {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 20px;
  }

  .main-title {
    font-size: 60px;
    font-weight: 600;
  }

  h2 {
    position: relative;
    font-size: 42px;
    margin-top: 0;
    display: inline-block;

    &:after {
      content: '';
      width: 20%;
      max-width: 70px;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      background: ${Variable.colorSec};
    }
  }

  .supText {
    font-size: 0.7rem;
    display: block;
  }
`

export const GlobalWrapper = styled.section`
  display: flex;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 2px;
`
