import PropTypes from 'prop-types'
import { Component } from 'react'

import attachRoutes from './routes'

class App extends Component {
  render() {
    const { location } = this.props
    return attachRoutes({ location })
  }
}

App.propTypes = {
  location: PropTypes.object,
}

export default App
