import styled from 'styled-components'

import Variable from 'Constant/variable.styled'
import truck from 'Assets/truck.jpg'

export const StWelcome = styled.div`
  background-image: url("${truck}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

export const StBoxInfo = styled.div`
  padding: 30px;
  background: rgb(244, 244, 244, 0.8) none repeat scroll center left;
  border-radius: 4px;
  margin-top: 50px;
  margin-right: 50px;

  h3 {
    display: flex;
    align-items: center;
    font-size: 18px;

    svg {
      margin-right: 10px;
    }
  }

  p {
    display: flex;
    align-items: center;
    margin: 26px 0;

    svg {
      margin-right: 10px;
    }
  }

  a {
    color: ${Variable.colorFooter};
    text-decoration: none;
  }
`
