import React, { Component } from 'react'

import {
  AiFillPhone,
  AiFillMail,
} from 'react-icons/ai'

import {
  FaAddressCard,
} from 'react-icons/fa'

import {
  BsFillPeopleFill,
} from 'react-icons/bs'

import {
  StWelcome,
  StBoxInfo,
} from './Welcome.styled'

class Welcome extends Component {
  render() {
    return (
      <StWelcome>
        <StBoxInfo>
          <h1>Drazen Logistics</h1>
          <p>
            <AiFillPhone /> <a href="tel:+17272398640">+1(727) 239-8640</a>
          </p>
          <p>
            <AiFillMail /> <a target="_blank" rel="noreferrer noopener" href="mailto:drazen@drazenlogistics.com">drazen@drazenlogistics.com</a>
          </p>
          <p>
            <FaAddressCard /> <a target="_blank" rel="noreferrer noopener" href="https://goo.gl/maps/2H3NVT5Z12PW7Cso7">11203 49th St N BLDG F, Clearwater, FL 33762, United States</a>
          </p>

          <hr />

          <h3><BsFillPeopleFill /> Now Hiring Company Drivers & Owner Operators!!! </h3>

          Call - Drazen <a href="tel:+17272398640">(727) 239-8640</a>
        </StBoxInfo>
      </StWelcome>
    )
  }
}

export default Welcome
