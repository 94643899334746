// Router file
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Welcome from 'Scenes/welcome'

const attachRoutes = ({ location }) => (
  <Switch location={location}>
    <Route
      exact
      path="/"
      component={Welcome}
    />
  </Switch>
)

export default attachRoutes
